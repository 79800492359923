import request from '../utils/request'

export function getAvailableCoursesI() {
  return request({
    method: 'GET',
    url: '/available-courses'+'?type=i',
  })
}
export function getAvailableCoursesS() {
  return request({
    method: 'GET',
    url: '/available-courses'+'?type=s',
  })
}
