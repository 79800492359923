<template>
  <div class="online-courses-bg">
    <common-banner
      :bannerTip="$t('OnlineCourses.tip2')"
      :bannerImg="require('../../assets/images/online-course-banner.webp')"
      :bannerTitle1="$t('OnlineCourses.tip1')"
      :bannerTitle2="$t('OnlineCourses.title')"
      colorType="purple"
    />
    <div class="text-content">
      <p>
        <span>{{ $t('OnlineCourses.content_one') }}</span
        >{{ $t('OnlineCourses.content_two') }}
      </p>
      <div class="content-title">
        <div class="left">
          <p class="line-left1"></p>
          <p class="line-left2"></p>
        </div>
        <p class="title">{{ $t('OnlineCourses.tip3') }}</p>
        <div class="right">
          <p class="line-right1"></p>
          <p class="line-right2"></p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-right"></div>
      <div class="list-img">
        <div :class="['list list' + (index + 1), isLogin ? 'login' : 'no-login']" v-for="(item, index) in courses" :key="index" @click="goCourse(index, item.courseId)" v-visible="index != 7">
          <template v-if="index !== imgList.length - 1">
            <img :src="item.img" alt="" />
            <div class="mantle"></div>
            <div style="z-index: 3" class="content">
              <p class="title">{{ $t('OnlineCourses.' + item.unit) }}</p>
              <p class="txt" v-if="$i18n.locale == 'en'">{{ item.title_en }}</p>
              <p class="txt" v-else>{{ item.title_zh_tw }}</p>
              <p class="txt-small">{{ $t('OnlineCourses.' + item.txt2) }}</p>
              <p class="txt-small">{{ $t('OnlineCourses.' + item.txt3) }}</p>
            </div>
            <div v-if="item.finished || !isLogin" class="big-mantle" style="opacity: 0.6"></div>
            <div v-else class="big-mantle" style="opacity: 0"></div>
            <div class="logo-sock" :style="{ opacity: !isLogin ? 1 : 0 }"></div>
            <!--<div class='coming-soon' v-if='index==3'>
              {{ $t('OnlineCourses.coming_soon') }}
            </div>-->
            <div class="logo-tick" v-if="item.finished"></div>
            <div class="more_bg" @click="goPushCoursesDetail(item.courseId)">
              <p class="more">{{ $t('Home.more') }}<i class="el-icon-arrow-right"></i></p>
            </div>
            <!--<div class="more_bg" v-else>
              <p class="more">
                {{ $t('OnlineCourses.coming_soon') }}
              </p>
            </div>-->
          </template>
          <template v-else>
            <!-- <img class="img" :src="item.img" alt="" /> -->
          </template>
        </div>
      </div>

      <div class="bottom-logo" @click="goregister" v-if="!isLogin">
        <img class="icon" src="../../assets/images/svg/icon_icon1.svg" alt="" />
        <p>{{ $t('OnlineCourses.tip4') }}</p>
        <div class="botoom-line1"></div>
        <div class="bottom-line2"></div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonBanner from '@/components/CommonBanner'
import { getCourseReport } from '@/api/memberUser'
import { getAvailableCoursesI } from '@/api/courses'
export default {
  components: {
    CommonBanner,
  },
  data() {
    return {
      isLogin: null, //是否登入
      src: process.env.VUE_APP_BASE_AUTHORIZEDLOGIN,
      courses: [],
      imgList: [
        {
          img: require('../../assets/images/unit-1.webp'),
          //bg_img:require
        },
        {
          img: require('../../assets/images/unit-2.webp'),
        },
        {
          img: 'https://df7ocie544p6c.cloudfront.net/images/abust-us.jpg',
        },
        {
          img: require('../../assets/images/unit-4.png'),
        },
        {
          img: require('../../assets/images/unit-5.webp'),
        },
        {
          img: require('../../assets/images/unit-6.png'),
        },
        {
          img: require('../../assets/images/homepage-4.webp'),
        },
        {
          //img: require('../../assets/images/hkjcct_logo3.png'),
        },
      ],
      screenWidth: window.document.body.clientWidth,
    }
  },
  methods: {
    getAvailableCoursesI: async function () {
      let courses = await getAvailableCoursesI()
      if (courses.result != 'success') {
        this.$message({
          message: '獲取課程失敗',
          type: 'error',
        })
        return
      }
      courses = courses.data
      for (let i = 0; i < courses.length; i++) {
        this.courses.push({
          courseId: courses[i].course_id,
          ...courses[i].Course,
          finished: false,
          unit: 'img_item' + (i + 1),
          txt2: 'img_item1_txt1',
          txt3: 'img_item1_txt2',
          img: this.imgList[i].img,
          unitNum: courses[i].unit,
        })
      }
      // Add dummy
      this.courses.push({})
    },
    getCourseReport: async function () {
      if (!this.isLogin) {
        return
      }
      let res = await getCourseReport()
      if (res.result == 'success') {
        res = res.data.finished
        for (let i = 0; i < res.length; i++) {
          for (let j = 0; j < this.courses.length; i++) {
            if (res[i].Course.id == this.courses[j].courseId) {
              this.courses[j].finished = true
            }
          }
        }
      }
    },
    goregister() {
      this.$router.push('/message')
    },
    goCourse(index, courseId) {
      if (!this.isLogin) {
        this.$alert(this.$t('Protection.login_text'), this.$t('Protection.login_title'), {
          confirmButtonText: this.$t('Protection.login_ok'),
        })
        return
      }
      window.gtag('event', 'coach_easygenerate_click', {
        event_name: 'easygenerate_i',
        value: this.courses[index].unitNum,
      })
      // this.$router.push({
      //   path: `/onlineCoursesDetail`,
      //   query: {
      //     title,
      //     courseId,
      //   },
      // })
      window.open(this.src + (this.$i18n.locale == 'en' ? this.courses[index].easy_generator_link_en : this.courses[index].easy_generator_link_zh_tw))
    },
  },
  created() {
    // this.getCousresList()
    this.getAvailableCoursesI()
    this.isLogin = window.sessionStorage.getItem('token')
    this.getCourseReport()
  },
}
</script>

<style lang="scss" scoped>
.tick-container {
  position: relative;
  text-align: center;
}
.tick {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.online-courses-bg {
  .text-content {
    width: 80%;
    margin: 5% auto;
    font-size: 26px;
    .content-title {
      margin: 4% auto;
      letter-spacing: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #28327b;
      font-size: 32px;
      font-weight: bold;
      // font-family: 'NotoSansCJKtc-Bold';
      transform: skew(-10deg);
      overflow: visible;
      .left {
        width: 60px;
        height: 50px;
        position: relative;
        .line-left1 {
          width: 30px;
          height: 2px;
          background: #28327b;
          position: absolute;
          top: 20px;
          left: 12px;
        }
        .line-left2 {
          width: 30px;
          height: 2px;
          background: #28327b;
          position: absolute;
          top: 28px;
          left: 0px;
        }
      }
      .right {
        width: 60px;
        height: 50px;
        position: relative;
        .line-right1 {
          width: 40px;
          height: 2px;
          background: #28327b;
          position: absolute;
          top: 20px;
          right: 0px;
        }
        .line-right2 {
          width: 20px;
          height: 2px;
          background: #28327b;
          position: absolute;
          top: 28px;
          right: 18px;
        }
      }
    }
  }
  .bottom {
    position: relative;
    margin-bottom: 200px;
    .bottom-right {
      width: 60%;
      height: calc(100% + 120px);
      background-color: #f15922;
      position: absolute;
      right: 0;
      top: 0;
      transform: skew(-14deg) translateX(20%);
      z-index: -1;
    }
    .list-img {
      width: 85%;
      margin: 3% auto;
      padding-top: 5%;
      //background: #5394df;
      z-index: 1;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      overflow: hidden;
      .list {
        height: 400px;
        width: 24%;
        // margin-top: 1%;
        position: relative;
        transition: all ease 0.6s;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .big-mantle {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0%;
          right: 0%;
          background: #fff;
          opacity: 0;
        }
        .logo-sock {
          width: 30%;
          height: 30%;
          background: url('../../assets/images/svg/icon-lock.svg') no-repeat;
          position: absolute;
          top: 30%;
          right: 35%;
          z-index: 4;
          opacity: 0;
        }
        .coming-soon {
          position: absolute;
          top: 60%;
          left: 12%;
          font-weight: 900;
          width: 80%;
          z-index: 4;
          opacity: 1;
          font-size: 25px;
        }
        .logo-tick {
          width: 30%;
          height: 30%;
          background: url('../../assets/images/svg/icon-tick.svg') no-repeat;
          position: absolute;
          top: 73%;
          right: 2%;
          z-index: 4;
          opacity: 0;
        }
        .more_bg {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          height: 15px;
          background: #f15922;
          position: absolute;
          bottom: 0;
          transition: all ease 0.6s;
          .more {
            opacity: 0;
            font-size: 18px;
            font-weight: bold;
            color: #fff;
            cursor: pointer;
            margin-right: 2%;
          }
        }
        .mantle {
          opacity: 0.9;
          width: 100%;
          height: 47%;
          position: absolute;
          bottom: 0;
          background: #f15922;
          transition: all ease 0.6s;
        }
        .content {
          // background: red;
          width: 95%;
          position: absolute;
          bottom: 6%;
          right: 3%;
          color: #ffffff;
          text-align: right;
          letter-spacing: 1px;
          .title {
            font-size: 32px;
            color: white;
            text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
          }
          .txt {
            font-size: 22px;
            color: white;
            text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
          }
          .txt-small {
            font-size: 14px;
            color: white;
            text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
          }
        }
      }
      .list1 {
        img {
          width: 200%;
          transform: translateX(-16%);
        }
      }
      .list2 {
        img {
          width: 180%;
          transform: translateX(-40%);
        }
      }
      .list3 {
        img {
          width: 230%;
          transform: translateX(-24%);
        }
      }
      .list5 {
        img {
          width: 180%;
          transform: translateX(-15%);
        }
      }
      .list7 {
        img {
          width: 180%;
          //height: 100%;
          transform: translateX(-20%);
        }
      }
      .list8 {
        background: #f15922;
        display: flex;
        justify-content: center;
        align-items: center;
        /* .img {
          width: 80%;
          height: 30%;
        } */
      }
      .login {
        margin-top: -1%;
        margin-bottom: 2%;
        .logo-sock {
          opacity: 0;
        }
        .logo-tick {
          opacity: 1;
        }
        &:hover {
          transform: scale(1.08, 1.05);
          .more_bg {
            height: 40px;
            .more {
              opacity: 1;
            }
          }
          .mantle {
            opacity: 0.9;
          }
          .content {
            bottom: 10%;
          }
        }
      }
      .no-login {
        margin-top: 1%;
        .more_bg,
        .mantle {
          background: #f15922;
        }
        .logo-sock {
          opacity: 1;
        }
        .logo-tick {
          opacity: 0;
        }
        .big-mantle {
          opacity: 0.6;
        }
        .content {
          .title,
          .txt,
          .txt-small {
            // text-shadow: 5px 2px 2px #000;
            color: white;
            text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
          }
        }
      }
      // .list {
      //   .more_bg,
      //   .mantle {
      //     background: #f9d871;
      //   }
      //   .logo-sock {
      //     opacity: 1;
      //   }
      //   .big-mantle {
      //     opacity: 0.6;
      //   }
      //   .content {
      //     .title,
      //     .txt,
      //     .txt-small {
      //       text-shadow: 5px 2px 2px #000;
      //     }
      //   }
      // }
      .list3 {
        .more_bg,
        .mantle {
          background: #28327b;
        }
      }
      .list1,
      .list6 {
        .more_bg,
        .mantle {
          background: #28327b;
        }
      }

      // .block {
      //   background: #ff9696;
      //   .list-logo {
      //     width: 80%;
      //     height: 30%;
      //     padding-top: 40%;
      //   }
      // }
    }
    .bottom-logo {
      width: 32%;
      height: 8%;
      background-color: #28327b;
      position: absolute;
      right: 17%;
      bottom: -17%;
      transform: skew(-14deg);
      z-index: 4;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .icon {
        width: 40px;
        height: 40px;
        transform: skew(13deg);
        margin-right: 15px;
      }
      p {
        font-weight: bold;
        font-size: 30px;
        color: #ffffff;
        transform: skew(13deg);
      }
    }
    .botoom-line1 {
      height: 1px;
      width: 40%;
      background: #ffffff;
      position: absolute;
      right: -50px;
      bottom: 60px;
      z-index: 5;
    }
    .bottom-line2 {
      height: 1px;
      width: 20%;
      background: #ffffff;
      position: absolute;
      right: -50px;
      bottom: 50px;
      z-index: 5;
    }
  }
}
@media screen and (max-width: 480px) {
  .coming-soon {
    position: absolute;
    top: 60%;
    left: 12%;
    width: 80%;
    z-index: 4;
    opacity: 1;
    font-size: 10px !important;
  }
  .online-courses-bg {
    margin: 7% auto;
    .text-content {
      width: 100% !important;
      padding: 50px 50px 0 50px;
      margin: 0;
      box-sizing: border-box;
      p {
        font-size: 30px;
        span {
          font-weight: bold;
        }
      }
      .content-title {
        margin-top: 50px;
        .left {
          .line-left1 {
            left: 32px;
            top: 22px;
          }
          .line-left2 {
            left: 24px;
            top: 28px;
          }
        }
        .title {
          font-size: 30px;
          letter-spacing: 0px;
          transform: scale(0.9);
        }
        .right {
          .line-right1 {
            width: 38px;
            right: 21px;
            top: 22px;
          }
          .line-right2 {
            right: 39px;
            top: 28px;
          }
        }
      }
    }
    .bottom {
      padding-bottom: 100px;
      margin-bottom: 100px;
      .list-img {
        padding-top: 3%;
        .list {
          height: 400px;
          .mantle {
            height: 50%;
          }
          width: 45%;
          margin-top: 5%;
          .content {
            .title {
              font-size: 15px;
              line-height: 1.5;
            }
            .txt {
              font-size: 12px;
              letter-spacing: 0;
              line-height: 1.5;
            }
            .txt-small {
              font-size: 12px;
              line-height: 1.5;
              transform: scale(0.8);
              letter-spacing: 0;
              margin-right: -30px;
            }
          }
          .more_bg {
            height: 10px;
          }
        }
        .login:hover {
          .more_bg {
            height: 18px;
            .more {
              font-size: 12px;
            }
          }
        }
      }
      .bottom-right {
        width: 120%;
        height: 100%;
        transform: skew(-11deg) translateX(50%);
      }
      .bottom-logo {
        width: 60%;
        height: 5%;
        right: 18%;
        bottom: -2.5%;
        background-color: #0e85d4;
        transform: skew(-12deg);
        .icon {
          height: 47%;
        }
        p {
          font-size: 26px;
        }
      }
      .botoom-line1 {
        width: 39%;
        right: 10%;
        bottom: 35px;
      }
      .bottom-line2 {
        width: 10%;
        right: 12%;
        bottom: 25px;
      }
    }
  }
}
</style>
