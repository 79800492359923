<template>
  <div class="common-banner-container">
    <div class="banner" :class="colorType + '-theme'">
      <div class="top-right-con">
        <div class="right-title">
          <span>{{ bannerTip }}</span>
        </div>
      </div>
      <div class='fill'></div>
      <div class="img-box">
        <img class="img" :src="bannerImg" alt="banner" />
      </div>
      <div class="bottom-left-con"></div>
      <div class="top-left-con">
        <p class="banner-title1" v-html='bannerTitle1'></p>
        <p class="banner-title2">{{ bannerTitle2 }}</p>
      </div>
      <div class="line line1"></div>
      <div class="line line2"></div>
      <!--<div class="line line3"></div>
      <div class="line line4"></div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonBanner',
  props: {
    bannerTip: String,
    bannerImg: String,
    bannerTitle1: String,
    bannerTitle2: String,
    colorType: String
  }
}
</script>